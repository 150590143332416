.img-overlay {
  width: "75px";
  height: "75px";
  top: 0;
  left: 0;
  transition: all 0.3s ease;
  opacity: 0;
  cursor: pointer;
}

.img-overlay:hover {
  opacity: 0.8 !important;
}
